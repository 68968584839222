import { Component, Input, OnInit } from '@angular/core';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { DefaultSupplierDialogComponent } from '@app/views/settings/pages/business-entities/tabs/default-suppliers/default-supplier-dialog/default-supplier-dialog.component';
import { IBusinessEntityDefaultProductSupplierDto } from '@app/logic/business-entity/interfaces/i.business-entity.dto';
import { IBusinessEntityMappedItem, IBusinessEntityDefaultSupplierMappedItem } from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { BusinessEntityDefaultSupplierMappedItem } from '@app/logic/business-entity/business-entity.mapped';
import { NgForm } from '@angular/forms';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { BusinessEntityDefaultSuppliersLogicService } from '@app/logic/business-entity/business-entity.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { toPromise } from 'cb-hub-lib';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-business-entity-default-suppliers',
    templateUrl: './default-suppliers.component.html',
    styleUrls: ['./default-suppliers.component.scss'],
    providers: [
        UsersLogicService,
        BusinessEntityDefaultSuppliersLogicService,
        BusinessEntitiesLogicService
    ]
})
export class BusinessEntityDefaultSuppliersComponent
    extends BaseSimpleListViewDirective<IBusinessEntityDefaultProductSupplierDto, BusinessEntityDefaultSuppliersLogicService>
    implements OnInit {
    @Input() public mappedItem: IBusinessEntityMappedItem;
    @Input() public businessEntityForm: NgForm;
    public readonly columns = ['Supply Type', 'Business Account', ''];

    constructor(
        public readonly toastService: ToastService,
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        private readonly businessEntitiesDefaultSupplierLogicService: BusinessEntityDefaultSuppliersLogicService,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService
    ) {
        super(
            cbDialog,
            businessEntitiesDefaultSupplierLogicService,
            DefaultSupplierDialogComponent,
            'Default Supplier',
            BusinessEntityDefaultSupplierMappedItem,
            permissionsPermissions
        );
    }

    public handleSaveError(error: any): any {
        this.toastService.saveError(error);
        return error;
    }

    public handleSaveSuccess(result: IBusinessEntityDefaultProductSupplierDto | IBusinessEntityDefaultProductSupplierDto[]):
    IBusinessEntityDefaultProductSupplierDto | IBusinessEntityDefaultProductSupplierDto[] {
        this.toastService.saveSuccess();
        return result;
    }

    public editItemClickedAlternative = (businessEntityDefaultProductSupplierDto: IBusinessEntityDefaultProductSupplierDto): void => {
        const mappedItem: IBusinessEntityDefaultSupplierMappedItem = this.logicService.$createMappedItem(businessEntityDefaultProductSupplierDto, this.mappedItemCtor);

        this.openDialog(mappedItem, `Edit ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleEditItem.bind(this));
    };

    public ngOnInit(): void {
        this.otherData = { region: this.mappedItem };
        if (this.mappedItem.defaultProductSuppliers) {
            this.handleSearchResults(this.mappedItem.defaultProductSuppliers);
        }
    }

    public newItemClicked(): void {
        const mappedItem = this.businessEntitiesDefaultSupplierLogicService.$createMappedItem({}, this.mappedItemCtor);
        this.openDialog(mappedItem, `Add ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleNewItem.bind(this));
    }

    public deleteClicked(defaultSupplier: IBusinessEntityDefaultProductSupplierDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Default Supplier',
            message: 'Are you sure you want to delete this Default Supplier?',
            confirmed: this.handleDeleteDefaultSupplier.bind(this, defaultSupplier),
        });
    }

    public handleDeleteDefaultSupplier = (defaultInstaller: IBusinessEntityDefaultProductSupplierDto): void => {

        const deletePromise: Promise<IBusinessEntityDefaultProductSupplierDto[]> = toPromise(this.businessEntitiesDefaultSupplierLogicService.deleteDefaultSupplier(
            defaultInstaller.businessEntityId,
            defaultInstaller.supplyType.id));
        deletePromise.then((result: IBusinessEntityDefaultProductSupplierDto[]) => {
            if (result) {
                this.mappedItem.$reload()
                    .subOnce({
                        next: (x) => this.handleSearchResults(x.defaultProductSuppliers)
                    });
                this.handleSaveSuccess(result);
            }

        }).catch((error) => {
            this.handleSaveError(error);
        });
    };

    protected handleNewItem(item: IBusinessEntityDefaultSupplierMappedItem): void {
        if (item) {
            const dto = item.$getMappedDtoItem();
            const savePromise = toPromise(this.saveMethod(dto));
            savePromise.then((result: IBusinessEntityDefaultProductSupplierDto) => {
                this.handleSaveSuccess(result);
                this.businessEntitiesLogicService.$getItem(result.businessEntityId).subOnce(x => {
                    this.handleSearchResults(x.defaultProductSuppliers);
                });
            }).catch((error) => {
                this.handleSaveError(error);
            });
        }
    }

    public saveMethod(defaultSupplier: IBusinessEntityDefaultProductSupplierDto): Observable<any> {
        return this.businessEntitiesDefaultSupplierLogicService.saveDefaultSupplier(this.mappedItem.id, defaultSupplier);
    }

    public handleEditItem = (item: IBusinessEntityDefaultProductSupplierDto): void => {
        if (item) {
            const savePromise: Promise<IBusinessEntityDefaultProductSupplierDto> = toPromise(this.saveMethod(item));
            savePromise.then((result: IBusinessEntityDefaultProductSupplierDto) => {
                this.handleSaveSuccess(result);
                this.businessEntitiesLogicService.$getItem(item.businessEntityId).subOnce(x => {
                    this.handleSearchResults(x.defaultProductSuppliers);
                });
            }).catch((error) => {
                this.handleSaveError(error);
            });
        }
    };

    public sortSearchResults(searchResults: Array<IBusinessEntityDefaultProductSupplierDto>): Array<IBusinessEntityDefaultProductSupplierDto> {
        return searchResults.sort((a, b) => a.supplyType.path.localeCompare(b.supplyType.path));
    }
}
