import { Component, Input, OnInit } from '@angular/core';
import { UsersLogicService } from '@app/logic/users/users.logic.service';
import { BaseSimpleListViewDirective } from '@app/shared/base-views/base-simple-list-view.directive';
import { PermissionsPermissions } from '@app/core/permissions';
import { DefaultInstallerDialogComponent } from './default-installer-dialog/default-installer-dialog.component';
import { IBusinessEntityDefaultSupplierDto } from '@app/logic/business-entity/interfaces/i.business-entity.dto';
import {
    IBusinessEntityMappedItem,
    IBusinessEntityDefaultInstallerMappedItem
} from '@app/logic/business-entity/interfaces/i.business-entity.mapped';
import { BusinessEntityDefaultInstallerMappedItem } from '@app/logic/business-entity/business-entity.mapped';
import { NgForm } from '@angular/forms';
import { BusinessEntitiesLogicService } from '@app/logic/business-entity';
import { BusinessEntityDefaultInstallersLogicService } from '@app/logic/business-entity/business-entity.logic.service';
import { ToastService } from '@app/core/services/toast/toast.service';
import { Observable } from 'rxjs';
import { toPromise } from 'cb-hub-lib';
import { CbDialogService } from '@app/shared/components/dialog/cb-dialog.service';

@Component({
    selector: 'cb-business-entity-default-installers',
    templateUrl: './default-installers.component.html',
    styleUrls: ['./default-installers.component.scss'],
    providers: [
        UsersLogicService,
        BusinessEntityDefaultInstallersLogicService,
        BusinessEntitiesLogicService
    ]
})
export class BusinessEntityDefaultInstallersComponent
    extends BaseSimpleListViewDirective<IBusinessEntityDefaultSupplierDto, BusinessEntityDefaultInstallersLogicService>
    implements OnInit {
    @Input() public mappedItem: IBusinessEntityMappedItem;
    @Input() public businessEntityForm: NgForm;
    public readonly columns = ['Trade', 'Business Account', ''];

    constructor(
        public readonly toastService: ToastService,
        public readonly cbDialog: CbDialogService,
        public readonly permissionsPermissions: PermissionsPermissions,
        private readonly businessEntitiesDefaultInstallerLogicService: BusinessEntityDefaultInstallersLogicService,
        private readonly businessEntitiesLogicService: BusinessEntitiesLogicService
    ) {
        super(
            cbDialog,
            businessEntitiesDefaultInstallerLogicService,
            DefaultInstallerDialogComponent,
            'Default Installer',
            BusinessEntityDefaultInstallerMappedItem,
            permissionsPermissions
        );
    }

    public handleSaveError(error: any): any { return error; }

    public handleSaveSuccess(result: any): any { return result; }

    public editItemClickedAlternative = (businessEntityDefaultSupplierDto: IBusinessEntityDefaultSupplierDto): void => {
        const mappedItem: BusinessEntityDefaultInstallerMappedItem = this.logicService.$createMappedItem(businessEntityDefaultSupplierDto, this.mappedItemCtor);

        this.openDialog(mappedItem, `Edit ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleEditItem.bind(this));
    };

    public ngOnInit(): void {
        this.otherData = { region: this.mappedItem };
        if (this.mappedItem.defaultSuppliers) {
            this.handleSearchResults(this.mappedItem.defaultSuppliers);
        }
    }

    public newItemClicked(): void {
        const mappedItem = this.businessEntitiesDefaultInstallerLogicService.$createMappedItem({}, this.mappedItemCtor);
        this.openDialog(mappedItem, `Add ${this.dialogHeading}`)
            .afterClosed()
            .subOnce(this.handleNewItem.bind(this));
    }

    public deleteClicked(defaultInstaller: IBusinessEntityDefaultSupplierDto): void {
        this.cbDialog.confirm({
            dialogHeading: 'Delete Default Installer',
            message: 'Are you sure you want to delete this Default Installer?',
            confirmed: this.handleDeleteDefaultInstaller.bind(this, defaultInstaller),
        });
    }

    public handleDeleteDefaultInstaller = (defaultInstaller: IBusinessEntityDefaultSupplierDto): void => {
        this.businessEntitiesDefaultInstallerLogicService.deleteDefaultInstaller(
            defaultInstaller.businessEntityId,
            defaultInstaller.tradeType.id
        ).subOnce({
            next: deleted => {
                if (deleted) {
                    this.mappedItem.$reload()
                        .subOnce({
                            next: (x) => this.handleSearchResults(x.defaultSuppliers)
                        });
                }
            }
        });
    };

    protected handleNewItem(item: IBusinessEntityDefaultInstallerMappedItem): void {
        if (item) {
            const dto = item.$getMappedDtoItem();
            const savePromise = toPromise(this.saveMethod(dto));
            savePromise.then((result: IBusinessEntityDefaultSupplierDto) => {
                this.handleNext(this.handleSaveSuccess(result));
                this.businessEntitiesLogicService.$getItem(result.businessEntityId).subOnce(x => {
                    this.handleSearchResults(x.defaultSuppliers);
                });
            }).catch((error) => {
                this.handleError(this.handleSaveError(error));
            });
        }
    }

    protected handleNext(_: any): void {
        this.toastService.saveSuccess();
    }

    protected handleError(x: any): void {
        this.toastService.saveError(x);
    }

    public saveMethod(defaultSupplier: IBusinessEntityDefaultSupplierDto): Observable<any> {
        return this.businessEntitiesDefaultInstallerLogicService.saveDefaultInstaller(this.mappedItem.id, defaultSupplier);
    }

    public handleEditItem = (item: IBusinessEntityDefaultSupplierDto): void => {
        if (item) {
            const savePromise = toPromise(this.saveMethod(item));
            savePromise.then((result) => {
                this.handleNext(this.handleSaveSuccess(result));
                this.businessEntitiesLogicService.$getItem(item.businessEntityId).subOnce(x => {
                    this.handleSearchResults(x.defaultSuppliers);
                });
            }).catch((error) => {
                this.handleError(this.handleSaveError(error));
            });
        }
    };

    public sortSearchResults(searchResults: Array<IBusinessEntityDefaultSupplierDto>): Array<IBusinessEntityDefaultSupplierDto> {
        return searchResults.sort((a, b) => a.tradeType.path.localeCompare(b.tradeType.path));
    }
}
